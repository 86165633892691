import { routes } from '@utils/routes';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

function DownloadApp() {
  return (
    <div className="bg-appBg bg-center paddingClass flex lg:flex-row flex-col  items-center justify-between">
      <div>
        <h2 className="text-5xl">Download Our App</h2>
        <ul className="list-disc text-xl my-5 list-inside">
          <li>Easy and Quick Booking</li>
          <li>Real-Time driver tracking</li>
          <li>Secure In-App Card Payment</li>
          <li>
            Ride history and receipts
            <li>247 customer support</li>
          </li>
        </ul>
        <div className="flex items-center gap-2">
          <Link href={routes.androidApp}>
            <a
              target={'_blank'}
              className=" p-1 flex items-center justify-center rounded my-5"
            >
              <Image
                alt="googleStore"
                src={'/assets/images/android.png'}
                width={185}
                height={50}
              />
            </a>
          </Link>
          <Link href={routes.appleApp}>
            <a
              target={'_blank'}
              className=" p-1 flex items-center justify-center rounded my-5"
            >
              <Image
                alt="apple store"
                src={'/assets/images/appleStore.png'}
                width={185}
                height={50}
              />
            </a>
          </Link>
        </div>
      </div>
      <Image
        alt="Download app"
        src="/assets/images/download-app.png"
        width={407}
        height={416}
      />
    </div>
  );
}

export default DownloadApp;
