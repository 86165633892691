export const business = [
  {
    name: 'Business Account',
    description:
      'Travel services for both business and personal needs throughout Middlesbrough',
    href: '/accounts-customer',
    ImgSrc: '/assets/images/online_business_account.png',
    width: '60',
    height: '60',
    alt: 'Business Account',
  },
];
export const driver = [
  {
    name: 'DRIVE WITH US',
    description:
      'Make money while staying close to home. Flexible work where and when it suits you best,  join today. ',
    href: '/become-a-driver',
    ImgSrc: '/assets/images/driver-bk.png',
    width: '60',
    height: '60',
    alt: 'Become A Driver',
    _blank: '',
  },
  {
    name: 'DRIVER PORTAL',
    description: 'Online portal ',
    href: 'https://drivers.247radiocarz.co.uk/',
    ImgSrc: '/assets/images/driver-portal.png',
    width: '30',
    height: '30',
    alt: 'Driver Portal',
    _blank: '_blank',
  },
];
export const services = [
  {
    name: 'Airport Transfers',
    description:
      'Seamless airport transfers from door to departure lounge. All it takes for an enjoyable trip is one step outside of your doorway.',
    href: '/birmingham-airport-transfers',
    ImgSrc: '/assets/images/airport-transfers.png',
    width: '60',
    height: '60',
    alt: 'Airport Transfers',
  },
];
