/* This example requires Tailwind CSS v2.0+ */
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import Logo from '../../../public/assets/images/logo.png';
import Business from './menudata/Business';
import Drivers from './menudata/Drivers';
import Services from './menudata/Services';
import BusinessMobile from './menudataMobile/Business';
import DriversMobile from './menudataMobile/Drivers';
import ServicesMobile from './menudataMobile/Services';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const NavBar = () => {
  const router = useRouter();
  const [secondMenu, setSecondMenu] = useState('');
  return (
    <>
      <Popover className="sticky top-0 z-50 bg-white">
        <div className="flex items-center justify-between pr-10 border-b-2 border-gray-100 bg-darkGray md:space-x-5">
          <div className="flex justify-start relative h-20 w-[250px]">
            <span className="sr-only">247 Radio Carz</span>
            <div className="relative h-20 cursor-pointer w-60">
              <Link href="/">
                <a className="cursor-pointer">
                  <Image
                    src={Logo}
                    alt="247 Radio Carz"
                    layout="fill"
                    objectFit="contain"
                  />
                </a>
              </Link>
            </div>
          </div>

          <div className="-my-2 -mr-2 xl:hidden">
            <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-1 xl:flex">
            <Business />
            <Services />
            <Drivers />
            <Link href="/about-us">
              <div className="items-center p-10 text-white duration-700 cursor-pointer bg-primary/90 hover:bg-secondary/70">
                ABOUT US
              </div>
            </Link>
            <Link href="/">
              <div className="items-center p-10 text-black font-bold duration-700 cursor-pointer bg-secondary hover:bg-secondary/70">
                BOOK ONLINE
              </div>
            </Link>
          </Popover.Group>
        </div>

        <div>
          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 z-50 transition origin-top-right transform md:hidden mx-5 overflow-hidden my-2"
            >
              {({ close }) => (
                <div className="bg-white divide-y-2 rounded-lg shadow-lg divide-gray-50 ring-1 ring-black ring-opacity-5">
                  <div className=" pt-5 pb-6">
                    <div className="px-5 flex items-center justify-between my-5">
                      <div>
                        <div
                          className="relative h-20 cursor-pointer w-60"
                          onClick={() => {
                            router.push('/');
                            close();
                          }}
                        >
                          <Link href="/">
                            <a className="cursor-pointer">
                              <Image
                                src={Logo}
                                alt="Comms Source Logo"
                                width={70}
                                height={70}
                              />
                            </a>
                          </Link>
                        </div>
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    {secondMenu === 'business' ? (
                      <div>
                        <BusinessMobile
                          closeSmall={() => {
                            close();
                          }}
                          onBackClick={() => {
                            setSecondMenu('');
                          }}
                        />
                      </div>
                    ) : secondMenu === 'services' ? (
                      <div>
                        <ServicesMobile
                          closeSmall={() => {
                            close();
                          }}
                          onBackClick={() => {
                            setSecondMenu('');
                          }}
                        />
                      </div>
                    ) : secondMenu === 'drivers' ? (
                      <div>
                        <DriversMobile
                          closeSmall={() => {
                            close();
                          }}
                          onBackClick={() => {
                            setSecondMenu('');
                          }}
                        />
                      </div>
                    ) : (
                      <div className="mt-6 w3-container overflow-hidden  w3-animate-left">
                        <nav className="grid gap-y-2">
                          <Business
                            mobileScreen={true}
                            closeSmall={() => {
                              close();
                            }}
                            onSecondClick={() => {
                              setSecondMenu('business');
                            }}
                          />
                          <Services
                            mobileScreen={true}
                            closeSmall={() => {
                              close();
                            }}
                            onSecondClick={() => {
                              setSecondMenu('services');
                            }}
                          />
                          <Drivers
                            mobileScreen={true}
                            closeSmall={() => {
                              close();
                            }}
                            onSecondClick={() => {
                              setSecondMenu('drivers');
                            }}
                          />
                          <Link
                            // className="text-base font-medium text-gray-500 cursor-pointer hover:text-gray-900"
                            href="/about-us"
                          >
                            <a
                              onClick={() => {
                                close();
                              }}
                              className={` group duration-700   bg-primary   w-full py-2 flex items-center px-5 rounded  text-white/80 font-medium hover:bg-secondary/70 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2}`}
                            >
                              ABOUT US
                            </a>
                          </Link>
                          <Link href="/">
                            <a
                              onClick={() => {
                                close();
                              }}
                              className={` group duration-700   bg-primary   w-full py-2 flex items-center px-5 rounded  text-white/80 font-medium hover:bg-secondary/70 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2}`}
                            >
                              BOOK ONLINE
                            </a>
                          </Link>
                        </nav>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </div>
      </Popover>
    </>
  );
};

export default NavBar;
