export const company = [
  {
    name: '247 Radio Carz',
    trading:
      'www.247radiocarz.co.uk Domain and brand is a legal entity of Select Data Systems Ltd, company number 621681, registered address, Digital Office Centre, Balheary Road, swords, Dublin, K67 E5A0',
    address: {
      building: '1214a',
      street: 'Warwick Road, Acocks Green',
      town: 'Birmingham',
      postcode: 'B27 6BY',
    },
    apple_store_id: 'app-id=1241258685',
    google_store_id: 'app-id=uk.riide.twofourseven',
    compnay_email: 'admin@247radiocars.co.uk',
    tel_number: '0121 788 8888',
    tel_number_two: '0121 222 2222',
    dpo_email: '',
    dpo: '',
    vat_number: '-',
    compnay_number: '621681',
    website: 'https://www.247radiocarz.co.uk',
    dpo_reg_number: '-',
    updated_on: '12/12/2022',
    Copyright_year: '2023',
    logo_url: '/assets/images/logo.jpeg',
    logo_alt: '247 radio carz Logo',
    width: '180',
    height: '70',
    width_mobile: '180',
    height_mobile: '70',
    contactus_task_url: 'https://',
    FB_PIXEL_ID: '232026032956346',
  },
];
// Check List - make sure update below files before making this website live
// 1- robots_txt: 'update robots.txt file domain details
// 1-   robots_txt: 'update robots.txt file domain details
// 2-  regenarate manifest.json file and icons and put in public folder
