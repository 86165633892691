import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { business } from './data';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Broadband = ({ closeSmall, mobileScreen, onSecondClick }) => {
  const router = useRouter();
  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              onClick={() => { mobileScreen && onSecondClick(); }}
              className={`${open ? 'text-gray-900' : 'text-gray-500'} group duration-700  inline-flex bg-primary items-center  ${mobileScreen ? 'w-full py-2 flex items-center justify-between px-5' : 'p-10'}  text-white/80 font-medium hover:bg-secondary/70 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2}`}
            >
              <span>BUSINESS</span>
              {
                mobileScreen ?
                  <ChevronRightIcon
                    className={classNames(
                      open ? 'text-gray-600' : 'text-gray-400',
                      'ml-2 h-5 w-5 group-hover:text-gray-500'
                    )}
                    aria-hidden="true"
                  /> :
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-600' : 'text-gray-400',
                      'ml-2 h-5 w-5 group-hover:text-gray-500'
                    )}
                    aria-hidden="true"
                  />

              }
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 md:w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                {({ close }) => (
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-6 md:px-5 px-2 py-6 bg-white sm:gap-8 sm:p-8">
                      {business.map((item) => (
                        <div
                          key={item.name}
                          onClick={() => {
                            router.push(item.href);
                            close();
                            mobileScreen && closeSmall();


                          }}
                        >
                          <div className="flex items-start p-3 -m-3 rounded-lg cursor-pointer hover:bg-blue-100">
                            <Image
                              className="flex-shrink-0 w-12 h-12 text-indigo-600"
                              aria-hidden="true"
                              src={item.ImgSrc}
                              width={item.width}
                              height={item.height}
                              alt={item.alt}
                            />
                            <div className="ml-4">
                              <p className="text-base font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default Broadband;
