import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { recentPosts, resources, services } from './data';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Services = ({ closeSmall, mobileScreen, onSecondClick }) => {
  const router = useRouter();
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            onClick={() => { mobileScreen && onSecondClick() }}
            className={`${open ? 'text-gray-900' : 'text-gray-500'} group duration-700  inline-flex bg-primary items-center  ${mobileScreen ? 'w-full py-2 flex items-center justify-between px-5' : 'p-10'}  text-white/80 font-medium hover:bg-secondary/70 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2}`}
          >
            <span>SERVICES</span>
            {
              mobileScreen ?
                <ChevronRightIcon
                  className={classNames(
                    open ? 'text-gray-600' : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500'
                  )}
                  aria-hidden="true"
                /> :
                <ChevronDownIcon
                  className={classNames(
                    open ? 'text-gray-600' : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500'
                  )}
                  aria-hidden="true"
                />
            }
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-md px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0">
              {({ close }) => (
                <div className="overflow-hidden rounded-lg shadow-lg cursor-pointer ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                    {services.map((item) => (
                      <div
                        key={item.name}
                        onClick={() => {
                          router.push(item.href);
                          close();
                          mobileScreen && closeSmall();
                        }}
                      >
                        <div className="flex items-start p-3 -m-3 rounded-lg hover:bg-blue-100">
                          <Image
                            className="flex-shrink-0 w-12 h-12 text-indigo-600"
                            aria-hidden="true"
                            src={item.ImgSrc}
                            width={item.width}
                            height={item.height}
                            alt="Services"
                          />
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              {item.name}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {item.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Services;
