
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults");
import DownloadApp from '@common/DownloadApp';
import SmartBanner from 'react-smartbanner';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { JsonLdOrg, JsonLdReview } from '@components/config/SEO';
import { company } from '@components/config/Company';

const AppDownload = () => {
  const { apple_store_id, google_store_id } = company[0];
  return (
    <>
      <NextSeo
        title="Download Our Mobile App | Convenient Rides Anytime, Anywhere"
        description="Get instant access to reliable taxi services with our user-friendly mobile app. Download now for iOS and Android devices and enjoy features like real-time tracking, in-app payments, and easy bookings on the go."
        openGraph={{
          type: 'website',
          site_name: `${company[0].name}`,
          url: `${company[0].website}/app-download`,
          images: [
            {
              url: `${company[0].website}/assets/images/app-download.jpg`,
              width: 800,
              height: 600,
              alt: 'Download Our App',
            },
          ],
        }}
        canonical={`${company[0].website}/app-download`}
      />
      <JsonLdReview />
      <JsonLdOrg />
      <Head>
        <meta name="apple-itunes-app" content={apple_store_id} />
        <meta name="google-play-app" content={google_store_id} />
        <link rel="apple-touch-icon" href="icon.png" />
        <link rel="android-touch-icon" href="icon.png" />
        <link rel="windows-touch-icon" href="icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/icon.png"></link>
        <meta name="theme-color" content="#58ade0" />
        <link
          rel="stylesheet"
          href="https://www.w3schools.com/w3css/4/w3.css"
        ></link>
        <meta
          name="viewport"
          content="width=device-width, height=device-height"
        ></meta>
      </Head>
      <SmartBanner />
      <DownloadApp />
    </>
  );
};

export default AppDownload;
