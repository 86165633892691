import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({


    palette: {
        primary: {
            main: '#1F1D26',
        },
        secondary: {
            main: '#ffc417;',
        },
        gray: {
            main: '#5B5A66'
        },
        darkGray: {
            main: '#24212B'
        },

    },

});

export default theme;