
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults");
import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material';
import '../styles/globals.css';

import FeedbackWrapper from '@components/wrapper/FeedbackWrapper';
import theme from 'theme';
import Link from 'next/link';
import Script from 'next/script';
import CookieConsent from 'react-cookie-consent';
import Head from 'next/head';
import Footer from '@components/Layout/Footer';
import NavBar from '@components/Layout/navbar/Index';
import { company } from '../components/config/Company';
import { useEffect } from 'react';

function MyApp({ Component, pageProps }) {
  // Facebook Pixle
  useEffect(async () => {
    const { default: ReactPixel } = await import('react-facebook-pixel');
    ReactPixel.init(company[0].FB_PIXEL_ID, null, {
      autoConfig: true,
      debug: true,
    });
    ReactPixel.pageView();
    ReactPixel.track('ViewContent');
  });
  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/icon.png"></link>
        <meta name="theme-color" content="#58ade0" />

        <link
          rel="stylesheet"
          href="https://www.w3schools.com/w3css/4/w3.css"
        ></link>
        <meta
          name="viewport"
          content="width=device-width, height=device-height"
        ></meta>
      </Head>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script id="google_analytics" strategy="lazyOnload">
        {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
    page_path: window.location.pathname,
    });
`}
      </Script>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <FeedbackWrapper>
            <NavBar />
            <Component {...pageProps} />
            <Footer />
            <CookieConsent
              location="bottom"
              buttonText="ACCEPT"
              cookieName="CommsSourceCookies"
              style={{ background: '#5A5A5C' }}
              buttonStyle={{
                background: '#55D642',
                color: '#000000',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
              expires={150}
            >
              We are using cookies to give you the best experience on our
              website. You can find out more about our cookie policy
              <Link href="/legal/policy/cookie"> here.</Link>
            </CookieConsent>
          </FeedbackWrapper>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default MyApp;
