export const routes = {
  Home: '/',
  About: '/about',
  Contact: '/contact-us',
  BookOnline: '/book-online',
  appleApp: 'https://apps.apple.com/gb/app/247-carz/id1241258685',
  androidApp:
    'https://play.google.com/store/apps/details?id=uk.riide.twofourseven&hl=en',
  aboutUs: '/about-us',
  facebook: 'https://www.facebook.com/247CarzBirmingham/?locale=en_GB',
  twitter: 'https://twitter.com/247carz?lang=en',
  instagram: '',
  youtube: '',
};
export const headerTitles = [
  ['Home', '/'],

  ['Accounts', '/accounts'],
  ['Drive With Us', '/become-a-driver'],
  ['About', '/about'],
  ['Contact Us', '/contact-us'],
];
export const FooterMenu = [
  ['About', '/about-us'],
  ['Our Fleet', '/our-fleet'],
  ['Blog', '/blog'],
  ['Become a Driver', '/become-a-driver'],
  ['Contact Us', '/contact-us'],
  ['Account Customers', '/accounts-customer'],
  ['Terms & Conditions', '/terms-and-conditions'],
  ['Reviews', '/reviews'],
  ['Technology', '/technology'],
];
