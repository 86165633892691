import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { business } from './data';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const BusinessMobile = ({ closeSmall, onBackClick }) => {

  const router = useRouter();
  return (
    <div className='w3-container  w3-animate-right'>
      <div className='flex items-center gap-2 mb-5 ' onClick={() => { onBackClick() }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        <div>
          Go Back
        </div>
      </div>
      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">

        <div className="relative grid gap-6 md:px-5 px-2 py-6 bg-white sm:gap-8 sm:p-8">
          {business.map((item) => (
            <div
              key={item.name}
              onClick={() => {

                closeSmall()
                router.push(item.href);

              }}
            >
              <div className="flex items-start p-3 -m-3 rounded-lg cursor-pointer hover:bg-blue-100">
                <Image
                  className="flex-shrink-0 w-12 h-12 text-indigo-600"
                  aria-hidden="true"
                  src={item.ImgSrc}
                  width={item.width}
                  height={item.height}
                  alt={item.alt}
                />
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">
                    {item.name}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusinessMobile;
