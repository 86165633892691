import Head from 'next/head';
import Script from 'next/script';

export const JsonLdWebsite = () => {
  return (
    <Script
      id="my-script"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          name: '247 Radio Carz',
          url: 'https://www.247radiocarz.co.uk/',
          sameAs: [
            'https://www.facebook.com/247CarzBirmingham/',
            'https://twitter.com/247carz?lang=en',
          ],

          author: {
            '@type': 'Corporation',
            name: '247 Radio Carz',
            logo: 'https://www.247radiocarz.co.uk/assets/images/logo.png',
            url: 'https://www.247radiocarz.co.uk/',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                telephone: '+44 121 788 8888',
                contactType: 'customer services',
                areaServed: 'UK',
              },
              {
                '@type': 'ContactPoint',
                telephone: '+44 121 788 8888',
                contactType: 'customer services',
                contactOption: 'We are available 24/7',
                areaServed: 'UK',
              },
            ],
          },
        }),
      }}
    />
  );
};

export const JsonLdOrg = () => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            name: '247 Radio Carz',
            url: 'https://www.247radiocarz.co.uk/',
            logo: 'https://www.247radiocarz.co.uk/assets/images/logo.png',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                telephone: '0121 788 8888',
                contactType: '24/7 Call or Book/Quote Online',
                areaServed: 'GB',
                availableLanguage: 'en',
              },
            ],
            sameAs: ['https://www.facebook.com/247CarzBirmingham/'],
          }),
        }}
      />
    </Head>
  );
};

export const JsonLdFaq = () => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: [
              {
                '@type': 'Question',
                name: 'How do I book a taxi?',
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: 'To make a booking, you can use our mobile app, our website booker, or call our 24/7 Call Centre at 01642 666666.',
                },
              },
              {
                '@type': 'Question',
                name: 'Do you allow pre-bookings of cars?',
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: 'Yes please feel free to book a journey in advance.In order to confirm your booking, you will have the option to specify the pick-up time and date.',
                },
              },
              {
                '@type': 'Question',
                name: '',
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: 'Canceling a booking with us is easy. Just call our 24/7 customer service line for hassle-free cancellation. Your card will be refunded promptly but depending on your bank, this process may take up to five working days to complete. Just sit back and relax knowing your trip has been cancelled without any worry or stress.',
                },
              },
            ],
          }),
        }}
      />
    </Head>
  );
};

export const JsonLdReview = () => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',

            '@type': 'Product',
            name: '247 Radio Carz',
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '4.2',
              bestRating: '5',
              worstRating: '3',
              ratingCount: '758',
            },
            reviews: [
              {
                '@type': 'Review',
                reviewBody:
                  'All good. Nice, clean car, punctual, friendly driver. But the car quoted when booked was 28 - 29 pounds. The cost was 38. There was no additional traffic so no reason why they could not have been more accurate when estimating the cost. It feels dine on purpose to win the service.',
                author: {
                  '@type': 'Person',
                  name: 'Jorge Alvarez',
                },
                datePublished: '2023-02-01',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '3',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  "247 Radio Car are really reliable, well mannered, always on time, they've never let me down and the driver's are so friendly, always talk really nicely. There the best taxi service I have come across in the whole of Birmingham. The other taxi services are rude, don't even speak nicely to the passengers and sometimes don't even turn up. I'm very happy with the service 247 Radio Car provid.",
                author: {
                  '@type': 'Person',
                  name: 'saika abbas',
                },
                name: 'saika abbas',
                datePublished: '2023-05-18',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  "EXCELLENT 🌟🌟🌟🌟🌟 Got to me within 4 minutes of calling. The driver was super polite and really helpful with my bags.Only a flying visit as I got off at the wrong train station ans needed to get back, but if I were ever back in the area, I'd most certainly use their services again.",
                author: {
                  '@type': 'Person',
                  name: 'Ryan Green - Actor',
                },
                name: 'Ryan Green - Actor',
                datePublished: '2023-04-25',
              },
              {
                '@type': 'Review',
                reviewBody:
                  'Lovely driver. Map sent him to the wrong place at first and he came to find me in super quick time with a lovely smile. The fare was much cheaper than Uber. Highly recommend.',
                author: {
                  '@type': 'Person',
                  name: 'Amanda White',
                },
                name: 'Amanda White',
                datePublished: '2023-02-25',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  'Great driver, really nice and chatty. Helped me with my bags of shopping to load them, took me to the garage and then home. He was a really nice man, good asset to 24/7 taxis. Also, using electric cars is a great idea for helping with the environment. He was very professional and a careful driver. Please pass this onto him that he gets 5* if I could give 10 I would.',
                author: {
                  '@type': 'Person',
                  name: 'Sarah Elisabethn',
                },
                name: 'Sarah Elisabeth',
                datePublished: '2023-05-14',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  'Booked a taxi then someone else had it, but they promptly sent another car. Driver was friendly and helpful. Would definitely use this company again, if in this area',
                author: {
                  '@type': 'Person',
                  name: 'Carol Clulow',
                },
                name: 'Carol Clulow',
                datePublished: '2023-03-23',
              },
              {
                '@type': 'Review',
                reviewBody:
                  'My taxi arrived in good time. The driver was friendly and professional. As a woman travelling alone this is important. The price of the fayre was right and not OTT as they can be.',
                author: {
                  '@type': 'Person',
                  name: 'Marie Payne',
                },
                name: 'Marie Payne',
                datePublished: '2023-04-25',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  "Very good service, drivers are polite and helpful, have used 247 Radio cars for a long time and can't fault them. Pleased with how the drivers can reasure me when I have an appointment and always get me there on time, regardless of traffic build up. Would Recommend them.",
                author: {
                  '@type': 'Person',
                  name: 'Lynn Tremelling',
                },
                name: 'Lynn Tremelling',
                datePublished: '2022-12-15',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  'Absolutely brilliant! Highly recommend and will definitely use again. After spending  so much time on an Uber App and getting no where. It was great to pick up the phone and speak to someone. 😊 Also cheaper than an Uber.  Thank you',
                author: {
                  '@type': 'Person',
                  name: 'Shelley Reader',
                },
                name: 'Shelley Reader',
                datePublished: '2022-05-16',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  'First time I have used their private hire taxis & would certainly recommend them. Very prompt pick up, texts to confirm booking, text to confirm taxi had arrived & gave description & registration of the car.Very polite drivers & felt very safe with their driving.',
                author: {
                  '@type': 'Person',
                  name: 'Christine Rush',
                },
                name: 'Christine Rush',
                datePublished: '2022-03-25',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  "Your drivers are fantastic. I've used you in one iteration or another since Harborne cars on Serpentine Road prefab days & you've always been there!! Many excellent drivers!!",
                author: {
                  '@type': 'Person',
                  name: 'Jonathan Sharman',
                },
                name: 'Jonathan Sharman',
                datePublished: '2022-04-20',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  'Took us home 120miles after a motorway breakdown and our car couldn’t be recovered that day. Great service, very courteous and friendly driver, got my family home 5*. Thank you',
                author: {
                  '@type': 'Person',
                  name: 'Chris K',
                },
                name: 'Chris K',
                datePublished: '2022-02-16',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  'I am very impressed with the taxi services.They always arrive on time.If they are delayed they inform you about it. They get my vote everytime.Friendly helpful drivers.',
                author: {
                  '@type': 'Person',
                  name: 'rosaline pryce',
                },
                name: 'rosaline pryce',
                datePublished: '2022-03-16',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  'Excellent service going out and coming back, only a short journey but both drivers very accommodating and respectful. Much better than previous years.',
                author: {
                  '@type': 'Person',
                  name: 'William Steele',
                },
                name: 'William Steele',
                datePublished: '2022-03-19',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                reviewBody:
                  'Very good driver. Very polite driver. As I am disabled, he was so good taking my bags to the front door. Sad that the waiting time sometimes long due to no drivers. Will use them happily again. 😊',
                author: {
                  '@type': 'Person',
                  name: 'Hanna Stewart',
                },
                name: 'Hanna Stewart',
                datePublished: '2022-02-19',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
            ],
          }),
        }}
      />
    </Head>
  );
};
